define("enspire-surveys/components/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='lds-ring'>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  */
  {
    "id": "Ewy1/bnv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"lds-ring\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "enspire-surveys/components/loader.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});