define("enspire-surveys/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.timing = 2000;
    var config = {
      springstone: {
        header_logo_url: 'https://cdn.filestackcontent.com/KX1UVk4BTn2sCEJFdqsh',
        // png at least 100x100px
        name: 'Sprigstone'
      },
      enspire: {
        header_logo_url: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/enspire_logo.png',
        // png at least 100x100px
        name: 'Enspire'
      },
      yourbrand: {
        header_logo_url: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/yourbrand.png',
        // png at least 100x100px
        name: 'Your Brand'
      }
    };
    this.get('/v1/public/config', function (schema, request) {
      var clientNamespace = request.requestHeaders['X-Client-Namespace'];
      var data = {
        public_client_configs: [{
          id: 1,
          client_id: '9kLeBjyd',
          name: 'hello world',
          assets: {
            header_logo_url: config[clientNamespace]['header_logo_url']
          }
        }],
        public_clients: [{
          application_name: config[clientNamespace]['name'],
          client_config_id: '9kLeBjyd-config',
          id: '9kLeBjyd',
          locales: [{
            code: 'en-US',
            default: true,
            enabled: true,
            fallback_code: '',
            name: 'English (US)'
          }, {
            code: 'es',
            default: false,
            enabled: true,
            fallback_code: 'en-US',
            name: 'Español'
          }],
          name: config[clientNamespace]['name']
        }]
      };
      console.log(data);
      return data;
    });
    var surveyComponents = {
      components: [{
        label: 'HTML',
        tag: 'img',
        attrs: [{
          attr: 'src',
          value: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/springstone-survey-demo/springstone_logo.png'
        }, {
          attr: 'style',
          value: 'margin-bottom:2em'
        }],
        refreshOnChange: false,
        key: 'html',
        type: 'htmlelement',
        input: false,
        tableView: false
      }, {
        label: 'Date of Exam',
        format: 'yyyy-MM-dd',
        tableView: false,
        enableMinDateInput: false,
        datePicker: {
          disableWeekends: false,
          disableWeekdays: false
        },
        enableMaxDateInput: false,
        enableTime: false,
        validate: {
          required: true
        },
        key: 'date_of_exam',
        type: 'datetime',
        input: true,
        widget: {
          type: 'calendar',
          displayInTimezone: 'viewer',
          locale: 'en',
          useLocaleSettings: false,
          allowInput: true,
          mode: 'single',
          enableTime: false,
          noCalendar: false,
          format: 'yyyy-MM-dd',
          hourIncrement: 1,
          minuteIncrement: 1,
          time_24hr: false,
          minDate: null,
          disableWeekends: false,
          disableWeekdays: false,
          maxDate: null
        }
      }, {
        label: 'Name of MD',
        tableView: true,
        validate: {
          required: true
        },
        key: 'name_of_md',
        type: 'textfield',
        input: true
      }, {
        label: 'MD Phone Number',
        tableView: true,
        validate: {
          required: true
        },
        key: 'md_phone_number',
        type: 'phoneNumber',
        input: true
      }, {
        label: 'Submit',
        showValidations: false,
        disableOnInvalid: true,
        tableView: false,
        key: 'submit',
        type: 'button',
        input: true,
        saveOnEnter: false
      }, {
        label: 'HTML',
        attrs: [{
          attr: 'style',
          value: 'margin-top:4em'
        }],
        content: 'Go To Doctor is brought to you by',
        refreshOnChange: false,
        key: 'html1',
        type: 'htmlelement',
        input: false,
        tableView: false
      }, {
        label: 'HTML',
        tag: 'img',
        attrs: [{
          attr: 'src',
          value: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/springstone-survey-demo/gallagher_logo.png'
        }, {
          attr: 'style',
          value: 'width:40%'
        }],
        refreshOnChange: false,
        key: 'html2',
        type: 'htmlelement',
        input: false,
        tableView: false
      }]
    };
    var surveyTranslations = {
      es: {
        'Go To Doctor is brought to you by': 'Go To Doctor es presentado por',
        'Date of Exam': 'Fecha de examen',
        'Name of MD': 'Nombre del MD',
        'MD Phone Number': 'Número de teléfono de MD',
        Submit: 'Enviar'
      }
    };
    var surveyComponents2 = {
      components: [{
        label: 'HTML',
        tag: 'img',
        className: '',
        attrs: [{
          attr: 'src',
          value: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/aetna-covid-19-vaccination/Vaccine-Confirmation-btn.png'
        }, {
          attr: 'style',
          value: 'margin-bottom:2em;width:100%'
        }],
        content: '',
        refreshOnChange: false,
        customClass: '',
        hidden: false,
        modalEdit: false,
        key: 'html',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'htmlelement',
        input: false,
        tableView: false,
        placeholder: '',
        prefix: '',
        suffix: '',
        multiple: false,
        defaultValue: null,
        protected: false,
        unique: false,
        persistent: false,
        clearOnHide: true,
        refreshOn: '',
        redrawOn: '',
        dataGridLabel: false,
        labelPosition: 'top',
        description: '',
        errorLabel: '',
        tooltip: '',
        hideLabel: false,
        tabindex: '',
        disabled: false,
        autofocus: false,
        dbIndex: false,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        widget: null,
        validateOn: 'change',
        validate: {
          required: false,
          custom: '',
          customPrivate: false,
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        allowCalculateOverride: false,
        encrypted: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        id: 'enw7kf'
      }, {
        label: 'Have you been given a COVID-19 vaccine in the last 9 months?',
        labelPosition: 'top',
        optionsLabelPosition: 'right',
        description: '',
        tooltip: '',
        customClass: '',
        tabindex: '',
        inline: false,
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        values: [{
          label: 'Yes',
          value: 'yes',
          shortcut: ''
        }, {
          label: 'No',
          value: 'no',
          shortcut: ''
        }],
        dataType: '',
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          onlyAvailableItems: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        errorLabel: '',
        key: 'have_you_been_given_a_covid-19_vaccine_in_the_last_9_months',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'radio',
        input: true,
        placeholder: '',
        prefix: '',
        suffix: '',
        multiple: false,
        unique: false,
        refreshOn: '',
        dataGridLabel: false,
        widget: null,
        validateOn: 'change',
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        inputType: 'radio',
        fieldSet: false,
        id: 'eh96r7',
        defaultValue: ''
      }, {
        label: 'Reason (optional):',
        labelPosition: 'top',
        placeholder: '',
        description: '',
        tooltip: '',
        prefix: '',
        suffix: '',
        widget: {
          type: 'input'
        },
        editor: '',
        autoExpand: false,
        customClass: '',
        tabindex: '',
        autocomplete: '',
        hidden: false,
        hideLabel: false,
        showWordCount: false,
        showCharCount: false,
        autofocus: false,
        spellcheck: true,
        disabled: false,
        tableView: true,
        modalEdit: false,
        multiple: false,
        persistent: true,
        inputFormat: 'html',
        protected: false,
        dbIndex: false,
        case: '',
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validateOn: 'change',
        validate: {
          required: false,
          pattern: '',
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          minLength: '',
          maxLength: '',
          minWords: '',
          maxWords: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        unique: false,
        errorLabel: '',
        key: 'reason_optional',
        tags: [],
        properties: {},
        conditional: {
          show: true,
          when: 'have_you_been_given_a_covid-19_vaccine_in_the_last_9_months',
          eq: 'no',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'textarea',
        rows: 3,
        wysiwyg: false,
        input: true,
        refreshOn: '',
        dataGridLabel: false,
        allowMultipleMasks: false,
        mask: false,
        inputType: 'text',
        inputMask: '',
        fixedSize: true,
        id: 'e58d2lg',
        defaultValue: ''
      }, {
        label: 'How many doses did your vaccine require?',
        labelPosition: 'top',
        optionsLabelPosition: 'right',
        description: '',
        tooltip: '',
        customClass: '',
        tabindex: '',
        inline: false,
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        values: [{
          label: '1 dose',
          value: '1_dose',
          shortcut: ''
        }, {
          label: '2 doses',
          value: '2_doses',
          shortcut: ''
        }, {
          label: 'Prefer not to specify',
          value: 'prefer_not_to_say',
          shortcut: ''
        }],
        dataType: '',
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          onlyAvailableItems: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        errorLabel: '',
        key: 'how_many_doses_did_your_vaccine_require',
        tags: [],
        properties: {},
        conditional: {
          show: true,
          when: 'have_you_been_given_a_covid-19_vaccine_in_the_last_9_months',
          eq: 'yes',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'radio',
        input: true,
        placeholder: '',
        prefix: '',
        suffix: '',
        multiple: false,
        unique: false,
        refreshOn: '',
        dataGridLabel: false,
        widget: null,
        validateOn: 'change',
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        inputType: 'radio',
        fieldSet: false,
        id: 'efqclg',
        defaultValue: ''
      }, {
        label: 'Who was the manufacturer of your vaccine?',
        labelPosition: 'top',
        optionsLabelPosition: 'right',
        description: '',
        tooltip: '',
        customClass: '',
        tabindex: '',
        inline: false,
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        values: [{
          label: 'Johnson & Johnson',
          value: 'johnson_and_johnson',
          shortcut: ''
        }, {
          label: "Other / I don't know",
          value: 'other_i_dont_know',
          shortcut: ''
        }],
        dataType: '',
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          onlyAvailableItems: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        errorLabel: '',
        key: 'who_was_the_manufacturer_of_your_vaccine_one_dose',
        tags: [],
        properties: {},
        conditional: {
          show: true,
          when: 'how_many_doses_did_your_vaccine_require',
          eq: '1_dose',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'radio',
        input: true,
        placeholder: '',
        prefix: '',
        suffix: '',
        multiple: false,
        unique: false,
        refreshOn: '',
        dataGridLabel: false,
        widget: null,
        validateOn: 'change',
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        inputType: 'radio',
        fieldSet: false,
        id: 'ewgt3yl',
        defaultValue: ''
      }, {
        label: 'Date you received your dose',
        labelPosition: 'top',
        displayInTimezone: 'viewer',
        useLocaleSettings: false,
        allowInput: true,
        format: 'MM-dd-YYYY',
        placeholder: '',
        description: 'MM/DD/YYYY (within past 9 months)',
        tooltip: '',
        customClass: '',
        tabindex: '',
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        shortcutButtons: [],
        enableDate: true,
        enableMinDateInput: false,
        datePicker: {
          minDate: '2021-01-01T12:00:00-06:00',
          maxDate: null,
          disable: '',
          disableFunction: '',
          disableWeekends: false,
          disableWeekdays: false,
          showWeeks: true,
          startingDay: 0,
          initDate: '',
          minMode: 'day',
          maxMode: 'year',
          yearRows: 4,
          yearColumns: 5
        },
        enableMaxDateInput: false,
        enableTime: false,
        timePicker: {
          showMeridian: true,
          hourStep: 1,
          minuteStep: 1,
          readonlyInput: false,
          mousewheel: true,
          arrowkeys: true
        },
        multiple: false,
        defaultValue: '',
        defaultDate: '',
        customOptions: {},
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        unique: false,
        validateOn: 'change',
        errorLabel: '',
        key: 'date_you_received_your_dose',
        tags: [],
        properties: {},
        conditional: {
          show: true,
          when: 'how_many_doses_did_your_vaccine_require',
          eq: '1_dose',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'datetime',
        timezone: '',
        input: true,
        widget: {
          type: 'calendar',
          displayInTimezone: 'viewer',
          locale: 'en',
          useLocaleSettings: false,
          allowInput: true,
          mode: 'single',
          enableTime: false,
          noCalendar: false,
          format: 'MM-dd-YYYY',
          hourIncrement: 1,
          minuteIncrement: 1,
          time_24hr: false,
          minDate: '2021-01-01T12:00:00-06:00',
          disabledDates: '',
          disableWeekends: false,
          disableWeekdays: false,
          disableFunction: '',
          maxDate: null
        },
        prefix: '',
        suffix: '',
        refreshOn: '',
        dataGridLabel: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        datepickerMode: 'day',
        id: 'ex6fghk'
      }, {
        label: 'Who was the manufacturer of your vaccine?',
        labelPosition: 'top',
        optionsLabelPosition: 'right',
        description: '',
        tooltip: '',
        customClass: '',
        tabindex: '',
        inline: false,
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        values: [{
          label: 'Moderna',
          value: 'moderna',
          shortcut: ''
        }, {
          label: 'Pfizer',
          value: 'pfizer',
          shortcut: ''
        }, {
          label: "Other / I don't know",
          value: 'other_i_dont_know',
          shortcut: ''
        }],
        dataType: '',
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          onlyAvailableItems: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        errorLabel: '',
        key: 'who_was_the_manufacturer_of_your_vaccine_two_dose',
        tags: [],
        properties: {},
        conditional: {
          show: true,
          when: 'how_many_doses_did_your_vaccine_require',
          eq: '2_doses',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'radio',
        input: true,
        placeholder: '',
        prefix: '',
        suffix: '',
        multiple: false,
        unique: false,
        refreshOn: '',
        dataGridLabel: false,
        widget: null,
        validateOn: 'change',
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        inputType: 'radio',
        fieldSet: false,
        id: 'e4pbnne',
        defaultValue: ''
      }, {
        label: 'Have you taken both doses?',
        labelPosition: 'top',
        optionsLabelPosition: 'right',
        description: '',
        tooltip: '',
        customClass: '',
        tabindex: '',
        inline: false,
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        values: [{
          label: 'Yes',
          value: 'yes',
          shortcut: ''
        }, {
          label: 'No',
          value: 'no',
          shortcut: ''
        }],
        dataType: '',
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          onlyAvailableItems: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        errorLabel: '',
        key: 'have_you_taken_both_doses',
        tags: [],
        properties: {},
        conditional: {
          show: true,
          when: 'how_many_doses_did_your_vaccine_require',
          eq: '2_doses',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'radio',
        input: true,
        placeholder: '',
        prefix: '',
        suffix: '',
        multiple: false,
        unique: false,
        refreshOn: '',
        dataGridLabel: false,
        widget: null,
        validateOn: 'change',
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        inputType: 'radio',
        fieldSet: false,
        id: 'e2xg1u',
        defaultValue: ''
      }, {
        label: 'Date you received first dose',
        labelPosition: 'top',
        displayInTimezone: 'viewer',
        useLocaleSettings: false,
        allowInput: true,
        format: 'MM-dd-YYYY',
        placeholder: '',
        description: 'MM/DD/YYYY (within past 9 months)',
        tooltip: '',
        customClass: '',
        tabindex: '',
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        shortcutButtons: [],
        enableDate: true,
        enableMinDateInput: false,
        datePicker: {
          minDate: '2021-01-01T12:00:00-06:00',
          maxDate: null,
          disable: '',
          disableFunction: '',
          disableWeekends: false,
          disableWeekdays: false,
          showWeeks: true,
          startingDay: 0,
          initDate: '',
          minMode: 'day',
          maxMode: 'year',
          yearRows: 4,
          yearColumns: 5
        },
        enableMaxDateInput: false,
        enableTime: false,
        timePicker: {
          showMeridian: true,
          hourStep: 1,
          minuteStep: 1,
          readonlyInput: false,
          mousewheel: true,
          arrowkeys: true
        },
        multiple: false,
        defaultValue: '',
        defaultDate: '',
        customOptions: {},
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        unique: false,
        validateOn: 'change',
        errorLabel: '',
        key: 'date_you_received_first_dose',
        tags: [],
        properties: {},
        conditional: {
          show: true,
          when: 'how_many_doses_did_your_vaccine_require',
          eq: '2_doses',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'datetime',
        timezone: '',
        input: true,
        widget: {
          type: 'calendar',
          displayInTimezone: 'viewer',
          locale: 'en',
          useLocaleSettings: false,
          allowInput: true,
          mode: 'single',
          enableTime: false,
          noCalendar: false,
          format: 'MM-dd-YYYY',
          hourIncrement: 1,
          minuteIncrement: 1,
          time_24hr: false,
          minDate: '2021-01-01T12:00:00-06:00',
          disabledDates: '',
          disableWeekends: false,
          disableWeekdays: false,
          disableFunction: '',
          maxDate: null
        },
        prefix: '',
        suffix: '',
        refreshOn: '',
        dataGridLabel: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        datepickerMode: 'day',
        id: 'ep459h'
      }, {
        label: 'Date you received second dose',
        labelPosition: 'top',
        displayInTimezone: 'viewer',
        useLocaleSettings: false,
        allowInput: true,
        format: 'MM-dd-YYYY',
        placeholder: '',
        description: 'MM-DD-YYYY (within past 9 months)',
        tooltip: '',
        customClass: '',
        tabindex: '',
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        shortcutButtons: [],
        enableDate: true,
        enableMinDateInput: false,
        datePicker: {
          minDate: '2021-09-01T12:00:00-05:00',
          maxDate: null,
          disable: '',
          disableFunction: '',
          disableWeekends: false,
          disableWeekdays: false,
          showWeeks: true,
          startingDay: 0,
          initDate: '',
          minMode: 'day',
          maxMode: 'year',
          yearRows: 4,
          yearColumns: 5
        },
        enableMaxDateInput: false,
        enableTime: false,
        timePicker: {
          showMeridian: true,
          hourStep: 1,
          minuteStep: 1,
          readonlyInput: false,
          mousewheel: true,
          arrowkeys: true
        },
        multiple: false,
        defaultValue: '',
        defaultDate: '',
        customOptions: {},
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        unique: false,
        validateOn: 'change',
        errorLabel: '',
        key: 'date_you_received_second_dose',
        tags: [],
        properties: {},
        conditional: {
          show: true,
          when: 'have_you_taken_both_doses',
          eq: 'yes',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'datetime',
        timezone: '',
        input: true,
        widget: {
          type: 'calendar',
          displayInTimezone: 'viewer',
          locale: 'en',
          useLocaleSettings: false,
          allowInput: true,
          mode: 'single',
          enableTime: false,
          noCalendar: false,
          format: 'MM-dd-YYYY',
          hourIncrement: 1,
          minuteIncrement: 1,
          time_24hr: false,
          minDate: '2021-09-01T12:00:00-05:00',
          disabledDates: '',
          disableWeekends: false,
          disableWeekdays: false,
          disableFunction: '',
          maxDate: null
        },
        prefix: '',
        suffix: '',
        refreshOn: '',
        dataGridLabel: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        datepickerMode: 'day',
        id: 'e1mhgyb'
      }, {
        label: "Please upload a photo of your vaccine card. If you're using a mobile device, you can take a picture:",
        labelPosition: 'top',
        description: '',
        tooltip: '',
        customClass: '',
        tabindex: '',
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        storage: '',
        dir: '',
        fileNameTemplate: '',
        image: false,
        uploadOnly: false,
        webcam: false,
        fileTypes: [{
          label: '',
          value: ''
        }],
        filePattern: '*',
        fileMinSize: '0KB',
        fileMaxSize: '1GB',
        multiple: false,
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        errorLabel: '',
        key: 'pleaseUploadAPhotoOfYourVaccineCardIfYoureUsingAMobileDeviceYouCanTakeAPictureBySelectingTheChooseFileOrBrowseButton',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: 'show = data.date_you_received_your_dose || (data.date_you_received_first_dose && data.date_you_received_second_dose)',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'file',
        imageSize: '200',
        input: true,
        placeholder: '',
        prefix: '',
        suffix: '',
        defaultValue: null,
        unique: false,
        refreshOn: '',
        dataGridLabel: false,
        widget: null,
        validateOn: 'change',
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        privateDownload: false,
        id: 'e6iz1yb'
      }, {
        type: 'button',
        label: 'Submit',
        key: 'submit',
        size: 'md',
        block: false,
        action: 'submit',
        disableOnInvalid: true,
        theme: 'primary',
        input: true,
        placeholder: '',
        prefix: '',
        customClass: '',
        suffix: '',
        multiple: false,
        defaultValue: null,
        protected: false,
        unique: false,
        persistent: false,
        hidden: false,
        clearOnHide: true,
        refreshOn: '',
        redrawOn: '',
        tableView: false,
        modalEdit: false,
        dataGridLabel: true,
        labelPosition: 'top',
        description: '',
        errorLabel: '',
        tooltip: '',
        hideLabel: false,
        tabindex: '',
        disabled: false,
        autofocus: false,
        dbIndex: false,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        widget: {
          type: 'input'
        },
        attributes: {},
        validateOn: 'change',
        validate: {
          required: false,
          custom: '',
          customPrivate: false,
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        conditional: {
          show: null,
          when: null,
          eq: ''
        },
        overlay: {
          style: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        allowCalculateOverride: false,
        encrypted: false,
        showCharCount: false,
        showWordCount: false,
        properties: {},
        allowMultipleMasks: false,
        leftIcon: '',
        rightIcon: '',
        id: 'e0g7pdk'
      }, {
        label: 'HTML',
        tag: 'div',
        className: '',
        attrs: [{
          attr: 'style',
          value: 'width:100%;border-top:4px solid #eaeaea;padding-top:2em;margin-top:2em'
        }, {
          attr: '',
          value: ''
        }],
        content: '<img src="https://enspire-internal-static.s3.us-east-2.amazonaws.com/yourlogo-footer-center.png" style="display:block;margin-left:auto;margin-right:auto;width:30%"/>',
        refreshOnChange: false,
        customClass: '',
        hidden: false,
        modalEdit: false,
        key: 'html1',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'htmlelement',
        input: false,
        tableView: false,
        placeholder: '',
        prefix: '',
        suffix: '',
        multiple: false,
        defaultValue: null,
        protected: false,
        unique: false,
        persistent: false,
        clearOnHide: true,
        refreshOn: '',
        redrawOn: '',
        dataGridLabel: false,
        labelPosition: 'top',
        description: '',
        errorLabel: '',
        tooltip: '',
        hideLabel: false,
        tabindex: '',
        disabled: false,
        autofocus: false,
        dbIndex: false,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        widget: null,
        validateOn: 'change',
        validate: {
          required: false,
          custom: '',
          customPrivate: false,
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        allowCalculateOverride: false,
        encrypted: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        id: 'e57ghs'
      }]
    };
    var surveyComponents3 = {
      components: [{
        label: 'HTML',
        tag: 'div',
        className: '',
        attrs: [{
          attr: 'style',
          value: 'width:100%;margin-bottom:2em'
        }, {
          attr: '',
          value: ''
        }],
        content: '<img src="https://enspire-internal-static.s3.us-east-2.amazonaws.com/yourlogo-footer-center.png" style="display:block;margin-left:auto;margin-right:auto;width:30%"/>',
        refreshOnChange: false,
        customClass: '',
        hidden: false,
        modalEdit: false,
        key: 'html1',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'htmlelement',
        input: false,
        tableView: false,
        placeholder: '',
        prefix: '',
        suffix: '',
        multiple: false,
        defaultValue: null,
        protected: false,
        unique: false,
        persistent: false,
        clearOnHide: true,
        refreshOn: '',
        redrawOn: '',
        dataGridLabel: false,
        labelPosition: 'top',
        description: '',
        errorLabel: '',
        tooltip: '',
        hideLabel: false,
        tabindex: '',
        disabled: false,
        autofocus: false,
        dbIndex: false,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        widget: null,
        validateOn: 'change',
        validate: {
          required: false,
          custom: '',
          customPrivate: false,
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        allowCalculateOverride: false,
        encrypted: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        id: 'ecj9qko'
      }, {
        label: 'HTML',
        tag: 'div',
        className: '',
        attrs: [{
          attr: 'style',
          value: 'font-weight:bold;margin-bottom:2em;font-size:1.5em;line-height:normal;text-align:center'
        }],
        content: 'Confirm that you completed your annual physical with the details below: ',
        refreshOnChange: false,
        customClass: '',
        hidden: false,
        modalEdit: false,
        key: 'html',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'htmlelement',
        input: false,
        tableView: false,
        placeholder: '',
        prefix: '',
        suffix: '',
        multiple: false,
        defaultValue: null,
        protected: false,
        unique: false,
        persistent: false,
        clearOnHide: true,
        refreshOn: '',
        redrawOn: '',
        dataGridLabel: false,
        labelPosition: 'top',
        description: '',
        errorLabel: '',
        tooltip: '',
        hideLabel: false,
        tabindex: '',
        disabled: false,
        autofocus: false,
        dbIndex: false,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        widget: null,
        validateOn: 'change',
        validate: {
          required: false,
          custom: '',
          customPrivate: false,
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        allowCalculateOverride: false,
        encrypted: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        id: 'espnf8'
      }, {
        label: 'Name of Doctor',
        labelPosition: 'top',
        placeholder: '',
        description: '',
        tooltip: '',
        prefix: '',
        suffix: '',
        widget: {
          type: 'input'
        },
        inputMask: '',
        allowMultipleMasks: false,
        customClass: '',
        tabindex: '',
        autocomplete: '',
        hidden: false,
        hideLabel: false,
        showWordCount: false,
        showCharCount: false,
        mask: false,
        autofocus: false,
        spellcheck: true,
        disabled: false,
        tableView: true,
        modalEdit: false,
        multiple: false,
        persistent: true,
        inputFormat: 'plain',
        protected: false,
        dbIndex: false,
        case: '',
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validateOn: 'change',
        validate: {
          required: false,
          pattern: '',
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          minLength: '',
          maxLength: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        unique: false,
        errorLabel: '',
        key: 'name_of_md',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'textfield',
        input: true,
        refreshOn: '',
        dataGridLabel: false,
        inputType: 'text',
        id: 'enri58o',
        defaultValue: ''
      }, {
        label: 'Date of Exam',
        labelPosition: 'top',
        displayInTimezone: 'viewer',
        useLocaleSettings: false,
        allowInput: true,
        format: 'yyyy-MM-dd',
        placeholder: '',
        description: '',
        tooltip: '',
        customClass: '',
        tabindex: '',
        hidden: false,
        hideLabel: false,
        autofocus: false,
        disabled: false,
        tableView: false,
        modalEdit: false,
        shortcutButtons: [],
        enableDate: true,
        enableMinDateInput: false,
        datePicker: {
          minDate: null,
          maxDate: null,
          disable: '',
          disableFunction: '',
          disableWeekends: false,
          disableWeekdays: false,
          showWeeks: true,
          startingDay: 0,
          initDate: '',
          minMode: 'day',
          maxMode: 'year',
          yearRows: 4,
          yearColumns: 5
        },
        enableMaxDateInput: false,
        enableTime: false,
        timePicker: {
          showMeridian: true,
          hourStep: 1,
          minuteStep: 1,
          readonlyInput: false,
          mousewheel: true,
          arrowkeys: true
        },
        multiple: false,
        defaultValue: '',
        defaultDate: '',
        customOptions: {},
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validate: {
          required: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        unique: false,
        validateOn: 'change',
        errorLabel: '',
        key: 'date_of_exam',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'datetime',
        timezone: '',
        input: true,
        widget: {
          type: 'calendar',
          displayInTimezone: 'viewer',
          locale: 'en',
          useLocaleSettings: false,
          allowInput: true,
          mode: 'single',
          enableTime: false,
          noCalendar: false,
          format: 'yyyy-MM-dd',
          hourIncrement: 1,
          minuteIncrement: 1,
          time_24hr: false,
          minDate: null,
          disabledDates: '',
          disableWeekends: false,
          disableWeekdays: false,
          disableFunction: '',
          maxDate: null
        },
        prefix: '',
        suffix: '',
        refreshOn: '',
        dataGridLabel: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        datepickerMode: 'day',
        id: 'e5h2gjq'
      }, {
        label: 'City of Exam',
        labelPosition: 'top',
        placeholder: '',
        description: '',
        tooltip: '',
        prefix: '',
        suffix: '',
        widget: {
          type: 'input'
        },
        inputMask: '',
        allowMultipleMasks: false,
        customClass: '',
        tabindex: '',
        autocomplete: '',
        hidden: false,
        hideLabel: false,
        showWordCount: false,
        showCharCount: false,
        mask: false,
        autofocus: false,
        spellcheck: true,
        disabled: false,
        tableView: true,
        modalEdit: false,
        multiple: false,
        persistent: true,
        inputFormat: 'plain',
        protected: false,
        dbIndex: false,
        case: '',
        encrypted: false,
        redrawOn: '',
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validateOn: 'change',
        validate: {
          required: false,
          pattern: '',
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          minLength: '',
          maxLength: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        unique: false,
        errorLabel: '',
        key: 'cityOfExam',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'textfield',
        input: true,
        refreshOn: '',
        dataGridLabel: false,
        inputType: 'text',
        id: 'e354djl',
        defaultValue: ''
      }, {
        label: 'State of Exam',
        labelPosition: 'top',
        widget: 'choicesjs',
        placeholder: '',
        description: '',
        tooltip: '',
        customClass: '',
        tabindex: '',
        hidden: false,
        hideLabel: false,
        uniqueOptions: false,
        autofocus: false,
        disabled: false,
        tableView: true,
        modalEdit: false,
        multiple: false,
        dataSrc: 'values',
        data: {
          values: [{
            label: 'Alabama',
            value: 'AL'
          }, {
            label: 'Alaska',
            value: 'AK'
          }, {
            label: 'Arizona',
            value: 'AZ'
          }, {
            label: 'Arkansas',
            value: 'AR'
          }, {
            label: 'California',
            value: 'CA'
          }, {
            label: 'Colorado',
            value: 'CO'
          }, {
            label: 'Connecticut',
            value: 'CT'
          }, {
            label: 'Delaware',
            value: 'DE'
          }, {
            label: 'District of Columbia',
            value: 'DC'
          }, {
            label: 'Florida',
            value: 'FL'
          }, {
            label: 'Georgia',
            value: 'GA'
          }, {
            label: 'Hawaii',
            value: 'HI'
          }, {
            label: 'Idaho',
            value: 'ID'
          }, {
            label: 'Illinois',
            value: 'IL'
          }, {
            label: 'Indiana',
            value: 'IN'
          }, {
            label: 'Iowa',
            value: 'IA'
          }, {
            label: 'Kansas',
            value: 'KS'
          }, {
            label: 'Kentucky',
            value: 'KY'
          }, {
            label: 'Louisiana',
            value: 'LA'
          }, {
            label: 'Maine',
            value: 'ME'
          }, {
            label: 'Montana',
            value: 'MT'
          }, {
            label: 'Nebraska',
            value: 'NE'
          }, {
            label: 'Nevada',
            value: 'NV'
          }, {
            label: 'New Hampshire',
            value: 'NH'
          }, {
            label: 'New Jersey',
            value: 'NJ'
          }, {
            label: 'New Mexico',
            value: 'NM'
          }, {
            label: 'New York',
            value: 'NY'
          }, {
            label: 'North Carolina',
            value: 'NC'
          }, {
            label: 'North Dakota',
            value: 'ND'
          }, {
            label: 'Ohio',
            value: 'OH'
          }, {
            label: 'Oklahoma',
            value: 'OK'
          }, {
            label: 'Oregon',
            value: 'OR'
          }, {
            label: 'Maryland',
            value: 'MD'
          }, {
            label: 'Massachusetts',
            value: 'MA'
          }, {
            label: 'Michigan',
            value: 'MI'
          }, {
            label: 'Minnesota',
            value: 'MN'
          }, {
            label: 'Mississippi',
            value: 'MS'
          }, {
            label: 'Missouri',
            value: 'MO'
          }, {
            label: 'Pennsylvania',
            value: 'PA'
          }, {
            label: 'Rhode Island',
            value: 'RI'
          }, {
            label: 'South Carolina',
            value: 'SC'
          }, {
            label: 'South Dakota',
            value: 'SD'
          }, {
            label: 'Tennessee',
            value: 'TN'
          }, {
            label: 'Texas',
            value: 'TX'
          }, {
            label: 'Utah',
            value: 'UT'
          }, {
            label: 'Vermont',
            value: 'VT'
          }, {
            label: 'Virginia',
            value: 'VA'
          }, {
            label: 'Washington',
            value: 'WA'
          }, {
            label: 'West Virginia',
            value: 'WV'
          }, {
            label: 'Wisconsin',
            value: 'WI'
          }, {
            label: 'Wyoming',
            value: 'WY'
          }],
          resource: '',
          json: '',
          url: '',
          custom: ''
        },
        dataType: '',
        idPath: 'id',
        valueProperty: '',
        template: '<span>{{ item.label }}</span>',
        refreshOn: '',
        refreshOnBlur: '',
        clearOnRefresh: false,
        searchEnabled: true,
        selectThreshold: 0.3,
        readOnlyValue: false,
        customOptions: {},
        useExactSearch: false,
        persistent: true,
        protected: false,
        dbIndex: false,
        encrypted: false,
        clearOnHide: true,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        allowCalculateOverride: false,
        validateOn: 'change',
        validate: {
          required: false,
          onlyAvailableItems: false,
          customMessage: '',
          custom: '',
          customPrivate: false,
          json: '',
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        unique: false,
        errorLabel: '',
        key: 'stateOfExam',
        tags: [],
        properties: {},
        conditional: {
          show: null,
          when: null,
          eq: '',
          json: ''
        },
        customConditional: '',
        logic: [],
        attributes: {},
        overlay: {
          style: '',
          page: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        type: 'select',
        indexeddb: {
          filter: {}
        },
        selectFields: '',
        searchField: '',
        minSearch: 0,
        filter: '',
        limit: 100,
        redrawOn: '',
        input: true,
        prefix: '',
        suffix: '',
        dataGridLabel: false,
        showCharCount: false,
        showWordCount: false,
        allowMultipleMasks: false,
        lazyLoad: true,
        authenticate: false,
        ignoreCache: false,
        fuseOptions: {
          include: 'score',
          threshold: 0.3
        },
        id: 'ev78fq1',
        defaultValue: ''
      }, {
        label: 'Submit',
        showValidations: false,
        disableOnInvalid: true,
        tableView: false,
        key: 'submit',
        type: 'button',
        input: true,
        saveOnEnter: false,
        placeholder: '',
        prefix: '',
        customClass: '',
        suffix: '',
        multiple: false,
        defaultValue: null,
        protected: false,
        unique: false,
        persistent: false,
        hidden: false,
        clearOnHide: true,
        refreshOn: '',
        redrawOn: '',
        modalEdit: false,
        dataGridLabel: true,
        labelPosition: 'top',
        description: '',
        errorLabel: '',
        tooltip: '',
        hideLabel: false,
        tabindex: '',
        disabled: false,
        autofocus: false,
        dbIndex: false,
        customDefaultValue: '',
        calculateValue: '',
        calculateServer: false,
        widget: {
          type: 'input'
        },
        attributes: {},
        validateOn: 'change',
        validate: {
          required: false,
          custom: '',
          customPrivate: false,
          strictDateValidation: false,
          multiple: false,
          unique: false
        },
        conditional: {
          show: null,
          when: null,
          eq: ''
        },
        overlay: {
          style: '',
          left: '',
          top: '',
          width: '',
          height: ''
        },
        allowCalculateOverride: false,
        encrypted: false,
        showCharCount: false,
        showWordCount: false,
        properties: {},
        allowMultipleMasks: false,
        size: 'md',
        leftIcon: '',
        rightIcon: '',
        block: false,
        action: 'submit',
        theme: 'primary',
        id: 'etiq2bj'
      }]
    };
    var surveyComponents4 = {
      title: 'Wizard',
      display: 'wizard',
      type: 'form',
      components: [{
        key: 'page1',
        input: false,
        components: [{
          label: 'HTML',
          tag: 'img',
          attrs: [{
            attr: 'src',
            value: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/nextier_logo.png'
          }],
          refreshOnChange: false,
          key: 'html',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'HTML',
          attrs: [{
            attr: 'style',
            value: 'font-weight:bold;margin-top:1em'
          }],
          content: 'HR Questions of the Week<br/>Why are we doing questions of the week?',
          refreshOnChange: false,
          key: 'html1',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'HTML',
          attrs: [{
            attr: '',
            value: ''
          }],
          content: 'In order to expand our knowledge, the HR team will participate in a friendly competition of 2 questions each week. A winner will be identified quarterly.',
          refreshOnChange: false,
          key: 'html3',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'HTML',
          tag: 'img',
          attrs: [{
            attr: 'src',
            value: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/nextier_car.png'
          }, {
            attr: 'style',
            value: 'margin-bottom:2em;'
          }],
          refreshOnChange: false,
          key: 'html4',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'Start your engines!',
          action: 'event',
          showValidations: false,
          tableView: false,
          key: 'startYourEngines',
          type: 'button',
          event: 'startSurvey',
          input: true
        }],
        title: 'First',
        type: 'panel',
        tableView: false,
        label: 'Panel'
      }, {
        tableView: false,
        key: 'page2',
        input: false,
        components: [{
          label: 'HTML',
          tag: 'img',
          attrs: [{
            attr: 'src',
            value: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/nextier_logo.png'
          }, {
            attr: 'style',
            value: 'margin-bottom:1em'
          }],
          refreshOnChange: false,
          key: 'html2',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'When employees leave work early or purposely work at a slow pace, they are engaging in:   ',
          optionsLabelPosition: 'right',
          inline: false,
          tableView: false,
          values: [{
            label: 'Property Deviance',
            value: 'propertyDeviance',
            shortcut: ''
          }, {
            label: 'Production Deviance',
            value: 'productionDeviance',
            shortcut: ''
          }, {
            label: 'Political Deviance',
            value: 'politicalDeviance',
            shortcut: ''
          }, {
            label: 'Personal Aggression',
            value: 'personalAggression',
            shortcut: ''
          }],
          validate: {
            required: true,
            customMessage: 'Please select at least one answer'
          },
          key: 'question_1',
          type: 'radio',
          input: true
        }, {
          label: 'Next',
          action: 'event',
          showValidations: false,
          tableView: false,
          key: 'page_1_next',
          type: 'button',
          event: 'surveyNextPage',
          input: true
        }],
        title: 'Page 2',
        type: 'panel',
        label: 'Panel'
      }, {
        tableView: false,
        key: 'page3',
        input: false,
        components: [{
          label: 'HTML',
          tag: 'img',
          attrs: [{
            attr: 'src',
            value: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/nextier_logo.png'
          }, {
            attr: 'style',
            value: 'margin-bottom:1em'
          }],
          refreshOnChange: false,
          key: 'html3',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'During which phase of the strategic planning process would a SWOT analysist be most useful?',
          optionsLabelPosition: 'right',
          inline: false,
          tableView: false,
          values: [{
            label: 'Evaluation',
            value: 'evaluation',
            shortcut: ''
          }, {
            label: 'Environmental Scan',
            value: 'environmental_scan',
            shortcut: ''
          }, {
            label: 'Construction',
            value: 'construction',
            shortcut: ''
          }, {
            label: 'Adjustment',
            value: 'adjustment',
            shortcut: ''
          }],
          validate: {
            required: true,
            customMessage: 'Please select at least one answer'
          },
          key: 'question_2',
          type: 'radio',
          input: true
        }, {
          label: 'Next',
          action: 'event',
          showValidations: false,
          tableView: false,
          key: 'next',
          type: 'button',
          input: true,
          event: 'surveyNextPage'
        }],
        title: 'Page 3',
        type: 'panel',
        label: 'Panel'
      }, {
        key: 'page4',
        input: false,
        components: [{
          label: 'HTML',
          tag: 'img',
          attrs: [{
            attr: 'src',
            value: 'https://enspire-internal-static.s3.us-east-2.amazonaws.com/nextier_logo.png'
          }, {
            attr: 'style',
            value: 'margin-bottom:1em'
          }],
          refreshOnChange: false,
          key: 'html5',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'HTML',
          attrs: [{
            attr: 'style',
            value: 'font-weight:bold'
          }],
          content: 'HR Questions of the Week',
          refreshOnChange: false,
          key: 'html6',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'HTML',
          attrs: [{
            attr: 'style',
            value: 'color:green; font-weight:bold;margin-bottom:1em'
          }],
          content: "You've completed this week's questions",
          refreshOnChange: false,
          key: 'html7',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'HTML',
          attrs: [{
            attr: '',
            value: ''
          }],
          content: 'Your score for this week is:',
          refreshOnChange: false,
          key: 'html8',
          type: 'htmlelement',
          input: false,
          tableView: false
        }, {
          label: 'Score',
          hideLabel: true,
          disabled: false,
          tableView: true,
          calculateValue: "value = ((data.question_1 === 'propertyDeviance') ? 1 : 0) + ((data.question_2 === 'construction') ? 1 : 0) + ' of 2'",
          attributes: {
            calculated: 'true'
          },
          key: 'score',
          customClass: 'calculated_value',
          type: 'textfield',
          input: true
        }],
        title: 'Last',
        type: 'panel',
        tableView: false,
        label: 'Panel'
      }, {
        type: 'button',
        disableOnInvalid: true,
        key: 'submit',
        tableView: false,
        label: 'Submit',
        input: true
      }],
      settings: {
        controller: "['$scope', function($scope) { $scope.$watch('submission.data', function(data) { console.log(data); }, true); }]"
      }
    };
    this.get('/v2/public/surveys/:id', function (schema, request) {
      var surveyID = request.params.id;
      var confirmPreventiveExam = {
        surveys: [{
          id: 's39Dk3',
          name: 'Confirm Preventive Exam',
          slug: 'confirm-preventative-exam',
          title: {
            'en-US': 'Confirm Preventive Exam'
          },
          components: surveyComponents,
          show_translations: true,
          translations: surveyTranslations,
          completed: false,
          allow_multiple_responses: true
        }]
      };
      var covid19VaccinationCard = {
        surveys: [{
          id: 's39Dk5',
          name: 'COVID-19 Vaccine Confirmation',
          slug: 'covid-19-vaccine-confirmation',
          title: {
            'en-US': 'COVID-19 Vaccine Confirmation'
          },
          components: surveyComponents2,
          completed: false,
          allow_multiple_responses: true
        }]
      };
      var confirmAnnualPhysicalCompletion = {
        surveys: [{
          id: '39239d',
          name: 'SGSCO Demo',
          slug: 'confirm-annual-physical-completion',
          title: {
            'en-US': 'Confirm annual physical completion'
          },
          components: surveyComponents3,
          completed: false,
          allow_multiple_responses: true
        }]
      };
      var nextTierQuestionnaire = {
        surveys: [{
          id: '19239d',
          name: 'NexTier Questions',
          slug: 'nextier-questions',
          title: {
            'en-US': 'NexTier Questions'
          },
          components: surveyComponents4,
          completed: false,
          allow_multiple_responses: true
        }]
      };

      if (surveyID === '1') {
        return confirmPreventiveExam;
      } else if (surveyID === '2') {
        return covid19VaccinationCard;
      } else if (surveyID === '3') {
        return confirmAnnualPhysicalCompletion;
      } else if (surveyID === '4') {
        return nextTierQuestionnaire;
      }
    });
    this.post('/v2/public/survey-responses', function (schema, request) {
      var data = {
        survey_response: {
          id: 1,
          confirmation_code: 'K8EID'
        }
      };
      return data;
    });
  }
});