define("enspire-surveys/components/page-load-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='min-h-screen pt-16 pb-12 flex flex-col bg-white'>
    <main
      class='flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'
    >
      <div class='py-16'>
        <div class='text-center'>
          <p class='text-sm font-semibold text-gray-600 uppercase tracking-wide'>404 error</p>
          <h1 class='mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>Page not
            found.</h1>
          <p class='mt-2 text-base text-gray-500'>Sorry, we couldn’t find the page you’re looking for.</p>
        </div>
      </div>
    </main>
  </div>
  */
  {
    "id": "ivHFPMmX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"min-h-screen pt-16 pb-12 flex flex-col bg-white\"],[12],[2,\"\\n  \"],[10,\"main\"],[14,0,\"flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"py-16\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-sm font-semibold text-gray-600 uppercase tracking-wide\"],[12],[2,\"404 error\"],[13],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl\"],[12],[2,\"Page not\\n          found.\"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"mt-2 text-base text-gray-500\"],[12],[2,\"Sorry, we couldn’t find the page you’re looking for.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "enspire-surveys/components/page-load-error.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});